import classNames from 'classnames';

import { useEffect, useState } from 'react';

interface ProgressBarType {
  name: string;
  level: number;
  startAnimation: boolean;
  styles: string;
}

function ProgressBar({
  name,
  level,
  startAnimation,
  styles,
}: ProgressBarType): React.ReactElement {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    // Only set the graphs when the set animation flag is set to true.
    if (startAnimation) {
      setPercentage(level || 0);
    }
  }, [level, startAnimation]);

  const progressBarClasses = classNames('progress-bar', {
    [`${styles}`]: true,
  });

  return (
    <div className="row progress-graph">
      <div className="col-lg-3 progress-graph__label">{name}</div>
      <div className="col-lg-9 progress-graph__progress">
        <div className="progress">
          <div
            className={progressBarClasses}
            role="progressbar"
            aria-valuenow={level}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{ width: `${percentage}%` }}
          />
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
