import useData from '../hooks/useData';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SyntheticEvent, useState } from 'react';
import { FaCircle, FaEllipsisV, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { NavType, ScrollToType } from '../interface';

function Nav({ onNavClick }: ScrollToType): React.ReactElement {
  const nav = useData('nav');
  const [toggleNav, setToggleNav] = useState(false);

  /**
   * Toggle navigation.
   */
  function toggleMobileNav(event: SyntheticEvent) {
    event.preventDefault();
    setToggleNav(!toggleNav);
  }

  const navigationListClasses = classNames('nav-list', {
    'nav-list--open': toggleNav,
  });

  const toggleClasses = classNames('nav-trigger', {
    'nav-trigger--open': toggleNav,
  });

  return (
    <section id="nav">
      <nav id="vertical-nav" className={navigationListClasses}>
        {
          <ul>
            {nav.map((item: NavType, index: number) => {
              const { title, hash } = item;
              const anchor = `#${hash}`;

              return (
                <li key={index} className="nav-list__item">
                  <Link
                    to={{ hash: anchor }}
                    onClick={(event) => onNavClick(event, anchor)}
                    data-number={index}
                  >
                    <span className="nav-list__item__label">{title}</span>
                    <FaCircle className="nav-list__item__dot" />
                  </Link>
                </li>
              );
            })}
          </ul>
        }
      </nav>
      <div
        className={toggleClasses}
        onClick={(event) => toggleMobileNav(event)}
      >
        <span className="nav-trigger__container">
          {toggleNav ? <FaTimes /> : <FaEllipsisV />}
        </span>
      </div>
    </section>
  );
}

Nav.defaultProps = { onNavClick: () => {} };

Nav.propTypes = { onNavClick: PropTypes.func };

export default Nav;
