import Skillset from './skillset';

function Skills(): React.ReactElement {
  return (
    <div id="skills" className="sections">
      <div className="container">
        <h2 className="section-heading divider-after">What I can do</h2>
        <Skillset />
      </div>
    </div>
  );
}

export default Skills;
