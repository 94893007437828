function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="divider divider-before">
      <div className="container">
        copyright &copy; {`2016 - ${currentYear}`} Robin Roy Julius
      </div>
    </footer>
  );
}

export default Footer;
