import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './styles.scss';

import NotFound from './components/errors/not-found';
import Home from './components/home';

function App(): React.ReactElement {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
