import useData from '../hooks/useData';

import ProgressBar from './progress-bar';
import VolunteerHistory from './volunteer-history';

import { useInView } from 'react-intersection-observer';
import {
  CertificationsType,
  LanguagesType,
  SpokenLanguagesType,
  VolunteerHistoryType,
} from '../interface';

function Skillset(): React.ReactElement {
  const certifications = useData('certifications');
  const languages = useData('languages');
  const programming = useData('programming');
  const software = useData('software');
  const volunteerHistory = useData('volunteerHistory');

  // Use the intersection observer library to check whether the div is in viewport.
  const { ref, inView } = useInView();

  return (
    <section id="other-information">
      <div className="row" ref={ref}>
        <div className="col-sm-4">
          <h3 className="other-information__subheading">Programming</h3>
          {programming.programming.map(
            (entry: LanguagesType, index: number) => (
              <ProgressBar key={index} {...entry} startAnimation={inView} />
            ),
          )}
        </div>

        <div className="col-sm-4">
          <h3 className="other-information__subheading">Frameworks</h3>
          {programming.frameworks.map((entry: LanguagesType, index: number) => (
            <ProgressBar key={index} {...entry} startAnimation={inView} />
          ))}
        </div>

        <div className="col-sm-4">
          <h3 className="other-information__subheading">Database</h3>
          {programming.database.map((entry: LanguagesType, index: number) => (
            <ProgressBar key={index} {...entry} startAnimation={inView} />
          ))}

          <h3 className="other-information__subheading">Servers</h3>
          {programming.webserver.map((entry: LanguagesType, index: number) => (
            <ProgressBar key={index} {...entry} startAnimation={inView} />
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-4">
          <h3 className="other-information__subheading">Languages</h3>
          {languages.map((entry: SpokenLanguagesType, index: number) => (
            <ProgressBar key={index} {...entry} startAnimation={inView} />
          ))}
        </div>

        <div className="col-sm-4">
          <h3 className="other-information__subheading">Operating Systems</h3>
          {software.os.map((entry: LanguagesType, index: number) => (
            <ProgressBar key={index} {...entry} startAnimation={inView} />
          ))}
        </div>

        <div className="col-sm-4">
          <h3 className="other-information__subheading">Software</h3>
          {software.software.map((entry: LanguagesType, index: number) => (
            <ProgressBar key={index} {...entry} startAnimation={inView} />
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col-sm-5">
          <h3 className="other-information__subheading">Volunteer History</h3>

          {volunteerHistory.map(
            (history: VolunteerHistoryType, index: number) => {
              const { event, description } = history;

              return (
                <div key={index}>
                  <h4 className="other-information__subtitle">{event}</h4>
                  {
                    <p>
                      {description.map((entry, index: number) => (
                        <VolunteerHistory key={index} {...entry} />
                      ))}
                    </p>
                  }
                </div>
              );
            },
          )}
        </div>

        <div className="col-sm-7">
          <h3 className="other-information__subheading">Certifications</h3>

          {certifications.map(
            (certification: CertificationsType, index: number) => {
              const { school, description } = certification;

              return (
                <div key={index}>
                  <h4 className="other-information__subtitle">{school}</h4>
                  <p>{description}</p>
                </div>
              );
            },
          )}

          <h3 className="other-information__subheading">Personal Qualities</h3>
          <p>
            Hard working, goal-oriented, responsible, highly motivated and
            enthusiastic, as well as exerting an outgoing personality and
            confident in the approach to an unfamiliar problem.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Skillset;
