import MajorProjects from './major-projects';
import PersonalProjects from './personal-projects';

function Projects(): React.ReactElement {
  return (
    <section id="projects" className="sections">
      <h2 className="section-heading divider-after">What I have worked on</h2>
      <MajorProjects />
      <PersonalProjects />
    </section>
  );
}

export default Projects;
