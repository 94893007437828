import Data from '../data';

interface DataType {
  [key: string]: any;
}

function useData(key: string) {
  const Dataset: DataType = Data;

  if (!(key in Dataset)) {
    return;
  }

  return Dataset[key];
}

export default useData;
