import useData from '../hooks/useData';

import ResumeEntry from './resume-entry';

import { FaFile } from 'react-icons/fa';
import { ResumeType } from '../interface';

function Resume(): React.ReactElement {
  const cv = useData('cv');
  const resume = useData('resume');

  return (
    <section id="resume" className="resume">
      <p className="text-center resume__download">
        <a href={cv} target="_blank" rel="noreferrer">
          <FaFile />
          Download my CV
        </a>
      </p>

      {resume.map((entry: ResumeType, index: number) => (
        <ResumeEntry key={index} {...entry} />
      ))}
    </section>
  );
}

export default Resume;
