import { FaExternalLinkAlt, FaGithub } from 'react-icons/fa';
import { CardType } from '../interface';

function Card({
  image,
  title,
  description,
  codingLanguage,
  links,
}: CardType): React.ReactElement {
  return (
    <div className="col-sm-6 col-lg-3">
      <div className="card">
        <img
          src={`${process.env.PUBLIC_URL}${image}`}
          alt={title}
          className="card__image img-fluid"
        />
        <h3 className="card__title">{title}</h3>
        <p className="card__description">{description}</p>
        <p className="coding-language">
          {codingLanguage.map((language, index) => (
            <span key={index} className="coding-language__text">
              {language}
            </span>
          ))}
        </p>

        {links && (
          <p className="card__links">
            {links.map((link, index) => {
              const { type, url } = link;

              return (
                <span key={index}>
                  <a href={url} target="_blank" rel="noreferrer">
                    {type === 'github' ? <FaGithub /> : <FaExternalLinkAlt />}

                    {type === 'github' ? 'Download source' : 'Visit website'}
                  </a>
                  <br />
                </span>
              );
            })}
          </p>
        )}
      </div>
    </div>
  );
}

export default Card;
