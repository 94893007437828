import AnimateHeight from 'react-animate-height';

import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

interface ResumeEntryType {
  company: string;
  role: string;
  location: string;
  date: string;
  description: Array<string>;
}

function ResumeEntry({
  company,
  role,
  location,
  date,
  description,
}: ResumeEntryType): React.ReactElement {
  const [toggleResume, setToggleResume] = useState(false);

  return (
    <div className="resume-entry">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-4">
            <h4 className="resume-entry__company">{company}</h4>
            <h5 className="resume-entry__role">{role}</h5>
          </div>
          <div className="col-sm-4">
            <h5 className="resume-entry__date">{date}</h5>
            <h6 className="resume-entry__location">{location}</h6>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-sm-8">
            <AnimateHeight duration={500} height={toggleResume ? 'auto' : 0}>
              <div className="resume-entry__description">
                {description.map((desc, index) => (
                  <p key={index}>{desc}</p>
                ))}
              </div>
            </AnimateHeight>
          </div>
        </div>

        <div className="row justify-content-center">
          {description && description.length ? (
            <div
              className="col-sm-8 resume-entry__toggle"
              onClick={() => setToggleResume(!toggleResume)}
            >
              <span className="resume-entry__toggle__button">
                {toggleResume ? (
                  <>
                    <FaAngleUp /> less
                  </>
                ) : (
                  <>
                    <FaAngleDown /> more
                  </>
                )}
              </span>
            </div>
          ) : (
            <div className="col-sm-8 resume-entry__toggle--disabled" />
          )}
        </div>
      </div>
    </div>
  );
}

export default ResumeEntry;
