import Contact from './contact/';
import CV from './cv/';
import Hero from './hero/';
import Footer from './layout/footer';
import Nav from './layout/nav';
import Projects from './projects/';
import Skills from './skills/';

import { SyntheticEvent } from 'react';

function Home() {
  /**
   * Smooth scroll to div.
   * @param  {Object} event React synthetic event object.
   * @param  {[type]} div   The div to scroll to.
   */
  function scrollToDiv(event: SyntheticEvent, div: string) {
    const el = document.querySelector(div);
    el &&
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  }

  return (
    <>
      <Nav onNavClick={scrollToDiv} />
      <Hero onNavClick={scrollToDiv} />
      <CV />
      <Projects />
      <Skills />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
