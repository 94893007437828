import useData from '../hooks/useData';

import ProjectItem from './project-item';

import { ProjectItemType } from '../interface';

function MajorProjects() {
  const content = useData('majorProjects');

  return (
    <div id="major-projects">
      {content.map((entry: ProjectItemType, index: number) => {
        return (
          <div key={index} className="project-item__container container-fluid">
            <ProjectItem {...entry} />
          </div>
        );
      })}
    </div>
  );
}

export default MajorProjects;
