import useData from '../hooks/useData';

import Marker from './marker';

import { GoogleMap, LoadScript, OverlayView } from '@react-google-maps/api';

function MyLocation() {
  const { center, current, key, options, styles, zoom } = useData('myLocation');

  return (
    <section id="my-location">
      <div id="map">
        <LoadScript googleMapsApiKey={key}>
          <GoogleMap
            center={center}
            options={options}
            mapContainerStyle={styles}
            zoom={zoom}
          >
            <OverlayView position={current} mapPaneName="markerLayer">
              <Marker text="Currently in this vicinity. If not, then I'm seriously lost..." />
            </OverlayView>
          </GoogleMap>
        </LoadScript>
      </div>
    </section>
  );
}

export default MyLocation;
