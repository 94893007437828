import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <section id="not-found">
      <div>
        <h1>Not Found (404)</h1>
        <h3>I'm not really sure where it's gone. =P</h3>
        <p>
          Let's just{' '}
          <Link to="/">
            go home <FaExternalLinkAlt />
          </Link>{' '}
          and forget it ever happened.
        </p>
      </div>
    </section>
  );
}

export default NotFound;
