import useData from '../hooks/useData';

function Thanks(): React.ReactElement {
  const content = useData('thanks');

  return (
    <section id="thanks">
      <div className="container-fluid">
        {content.map((entry: Array<string>, index: number) => (
          <h3 key={index}>{entry}</h3>
        ))}
      </div>
    </section>
  );
}

export default Thanks;
